import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
// import slugify from 'url-slug';
import urlSlug from 'url-slug';
import styles from './ThreeTile.style';
import { withTheme } from 'styled-components';
import Carousel from '../../molecules/Carousel';
import Heading from '../../atoms/Heading';
import Anchor from '../../atoms/Anchor';
import Para from '../../atoms/Para';
import ImageTextCard from '../../molecules/ImageTextCard';
import constants, { siteConstants } from '../../../constants/globalConstants';
import RichText from '../../atoms/RichText/RichText';
import { filterPromoData } from '../../../utils/utilityFunction';
import { imageRenditions } from './config';
import CarouselArrow from '../../atoms/CarouselArrow';
import { ContentfulLivePreview } from '@contentful/live-preview';
var ThreeTileBlock = styled.section.withConfig({
  componentId: "sc-ckwi5v-0"
})(["", ";"], styles);
var ThreeTile = function ThreeTile(_ref) {
  var _threeTileData$sys, _threeTileData$sys2, _threeTileData$cta$sy;
  var threeTileData = _ref.threeTileData,
    className = _ref.className,
    theme = _ref.theme;
  /**
   * Promotion data is filtered  based on promotionStart & promotionEnd
   * updated the filter function to iterate over elem's keys if it exists, and check if each value is null
   * this is due to the shape of the contentful graphql response. otheriwse, if already null, return false
   */

  var threeTileDataList = threeTileData.tile && threeTileData.tile.map(function (item) {
    return filterPromoData(item);
  }).filter(function (elem) {
    return elem !== null;
  }) || threeTileData.tileCollection && threeTileData.tileCollection.items.map(function (item) {
    return filterPromoData(item);
  }).filter(function (elem) {
    return elem && !Object.values(elem).every(function (value) {
      return value === null;
    });
  });
  var Settings = {
    slidesToScroll: 1,
    slidesToShow: 1,
    cssEase: 'linear',
    arrows: true,
    speed: 500,
    dots: true,
    prevArrow: __jsx(CarouselArrow, {
      type: "previous"
    }),
    nextArrow: __jsx(CarouselArrow, {
      type: "next"
    })
  };
  var variation = threeTileData.variation ? threeTileData.variation.toLowerCase() : '';
  var topAnchorclassName = "".concat(variation, "-link");
  var tileAnchorclassName = "nva-3-tile__card-link tile-link secondary ".concat(variation, "-link");
  var idVal = threeTileData.anchorLink ? urlSlug(threeTileData.anchorLink) : '';
  var richTextEmbeddedAsset = threeTileData.bodyText && threeTileData.bodyText.links && threeTileData.bodyText.links.assets.block || {};
  var topPadding = threeTileData.topPadding,
    bottomPadding = threeTileData.bottomPadding;
  var wrapperClassName = "col-xs-12 tile-wrapper";
  // top padding from contentful
  wrapperClassName += topPadding === null || topPadding === 'Default' || topPadding === undefined ? '' : " padding-top-override-".concat(topPadding.toLowerCase());
  // bottom padding from contentful
  wrapperClassName += bottomPadding === null || bottomPadding === 'Default' || bottomPadding === undefined ? '' : " padding-bottom-override-".concat(bottomPadding.toLowerCase());
  var attributes = {};
  if (idVal != '') attributes.id = idVal;
  if (className) attributes.className = "nva-3-tile ".concat(className);
  return __jsx("div", attributes, threeTileData && __jsx("div", {
    className: "container"
  }, __jsx(ThreeTileBlock, {
    className: "nva-3-tile__wrapper ".concat(wrapperClassName)
  }, (threeTileData === null || threeTileData === void 0 ? void 0 : threeTileData.categoryTitle) && __jsx(Para, {
    className: "nva-3-tile__category-title u-overline"
  }, threeTileData === null || threeTileData === void 0 ? void 0 : threeTileData.categoryTitle), (threeTileData === null || threeTileData === void 0 ? void 0 : threeTileData.sectionHeadline) && __jsx(Heading, {
    HeadingType: "h2",
    className: "nva-3-tile__heading three-tile-top-heading col-xs-10",
    contentfulLivePreview: ContentfulLivePreview.getProps({
      entryId: threeTileData === null || threeTileData === void 0 ? void 0 : (_threeTileData$sys = threeTileData.sys) === null || _threeTileData$sys === void 0 ? void 0 : _threeTileData$sys.id,
      fieldId: "sectionHeadline"
    })
  }, threeTileData === null || threeTileData === void 0 ? void 0 : threeTileData.sectionHeadline), threeTileData.bodyText && __jsx(RichText, {
    className: "col-xs-10 col-sm-8 col-md-10 nva-3-tile__description three-tile-top-description",
    jsonContent: threeTileData.bodyText && threeTileData.bodyText.bodyText || threeTileData.bodyText && threeTileData.bodyText.json,
    richTextEmbeddedAsset: richTextEmbeddedAsset,
    contentfulLivePreview: ContentfulLivePreview.getProps({
      entryId: threeTileData === null || threeTileData === void 0 ? void 0 : (_threeTileData$sys2 = threeTileData.sys) === null || _threeTileData$sys2 === void 0 ? void 0 : _threeTileData$sys2.id,
      fieldId: "bodyText"
    })
  }), threeTileData.cta && threeTileData.cta.url && threeTileData.cta.behavior && threeTileData.cta.label && __jsx(Para, {
    className: "nva-3-tile__link-wrapper ".concat(topAnchorclassName)
  }, __jsx(Anchor, {
    "data-entry-id": (_threeTileData$cta$sy = threeTileData.cta.sys) === null || _threeTileData$cta$sy === void 0 ? void 0 : _threeTileData$cta$sy.id,
    title: threeTileData.cta.label,
    to: threeTileData.cta.url,
    ctaBehavior: threeTileData.cta.behavior,
    hyperlinkType: threeTileData.cta.hyperlinkType,
    className: "nva-3-tile__anchor arrow secondary three-tile-top-cta",
    "aria-label": threeTileData.cta.ariaLabel,
    styleType: variation === constants.variations.variationB.toLowerCase() ? 'primary-anchor' : '',
    "data-analytics-type": constants.analytics.analyticsType.sectionCTA,
    "data-analytics-value": threeTileData === null || threeTileData === void 0 ? void 0 : threeTileData.sectionHeadline,
    "data-analytics-variation": threeTileData.variation
  }, threeTileData.cta.label)), __jsx(Carousel, _extends({
    styleType: "threeTileCarousel",
    className: "nva-3-tile__carousel ".concat(variation)
  }, Settings), threeTileDataList && threeTileDataList.map(function (tileData, index) {
    var _theme$threeTile, _cta$sys, _tileData$sys, _tileData$sys2, _tileData$sys3;
    var imgSrc = tileData.tileImage && tileData.tileImage.image && tileData.tileImage.image.file && tileData.tileImage.image.file.url || tileData.tileImage.image.url;
    var imgAltText = tileData.tileImage && tileData.tileImage.altText;
    var imgTitle = tileData.tileImage && (tileData.tileImage.title || tileData.tileImage.altText);
    var cta = tileData.tileCta;
    var linkStyle = '';
    if (variation === constants.variations.variationB.toLowerCase()) {
      linkStyle = 'secondary-anchor';
    }
    if (theme !== null && theme !== void 0 && (_theme$threeTile = theme.threeTile) !== null && _theme$threeTile !== void 0 && _theme$threeTile.noSecondaryAnchorOnThreeTile && variation === constants.variations.variationB.toLowerCase()) {
      linkStyle = 'primary-anchor';
    }
    return __jsx(ImageTextCard, {
      styleType: "three-tile",
      className: "nva-3-tile__card three-tile",
      ariaLabel: cta === null || cta === void 0 ? void 0 : cta.ariaLabel,
      headingText: tileData.tileHeadline,
      headingType: "h3",
      subParaText: tileData.subHeadline,
      subParaClassName: "heading_four tile-subheading",
      imageSource: imgSrc,
      imageAltText: imgAltText,
      imageTitle: imgTitle,
      linkTitle: tileData.tileHeadline,
      descriptionType: siteConstants.RICH_TEXT,
      descriptionText: tileData.tileText && tileData.tileText.tileText || tileData.tileText && tileData.tileText.json,
      variation: variation,
      key: index,
      ctaURL: cta && cta.url,
      ctaCopy: cta && cta.label,
      ctaBehavior: cta && cta.behavior,
      ctaHyperlinkType: cta && cta.hyperlinkType,
      ctaSysId: cta === null || cta === void 0 ? void 0 : (_cta$sys = cta.sys) === null || _cta$sys === void 0 ? void 0 : _cta$sys.id,
      imageClassName: "nva-3-tile__card-image tile-image",
      headingClassName: "nva-3-tile__card-heading tile-heading",
      subHeadingClassName: "nva-3-tile__card-subheading tile-subheading",
      paraClassName: "nva-3-tile__card-text tile-para",
      linkClassName: tileAnchorclassName,
      linkStyleType: linkStyle,
      dataAnalytics: {
        type: constants.analytics.analyticsType.tileCTA,
        value: tileData.tileHeadline,
        variation: variation
      },
      srcSetSetting: {
        imageRenditions: imageRenditions
      },
      contentfulLivePreviewHeadingText: ContentfulLivePreview.getProps({
        entryId: tileData === null || tileData === void 0 ? void 0 : (_tileData$sys = tileData.sys) === null || _tileData$sys === void 0 ? void 0 : _tileData$sys.id,
        fieldId: "tileHeadline"
      }),
      contentfulLivePreviewSubParaText: ContentfulLivePreview.getProps({
        entryId: tileData === null || tileData === void 0 ? void 0 : (_tileData$sys2 = tileData.sys) === null || _tileData$sys2 === void 0 ? void 0 : _tileData$sys2.id,
        fieldId: "subHeadline"
      }),
      contentfulLivePreviewDescriptionText: ContentfulLivePreview.getProps({
        entryId: tileData === null || tileData === void 0 ? void 0 : (_tileData$sys3 = tileData.sys) === null || _tileData$sys3 === void 0 ? void 0 : _tileData$sys3.id,
        fieldId: "tileText"
      })
    });
  })))));
};
ThreeTile.defaultProps = {
  threeTileData: '',
  className: ''
};
export default withTheme(styled(ThreeTile).withConfig({
  componentId: "sc-ckwi5v-1"
})(["", ";"], styles));